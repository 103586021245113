@if (url$ | async; as url) {
<iframe [src]="url | trust : 'resourceUrl'" frameborder="0"></iframe>
} @else if (props$ | async) {
<div class="info-container">
  <mat-spinner mode="indeterminate"> </mat-spinner>
</div>
} @else {
<div class="info-container">
  <div class="info-wrapper">
    <mat-icon>settings_suggest</mat-icon>
    <span>Napaka: Napačne nastavitve</span>
  </div>
</div>
}
